import React, { useState } from "react";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
// import ListItemText from "@material-ui/core/ListItemText";
// import Checkbox from "@material-ui/core/Checkbox";
import { Button, Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { Multiselect } from "multiselect-react-dropdown";
// import { updateChapter } from "../../redux/actions/bookActions";
// import { useDispatch } from "react-redux";

const UpdateChapterModal = ({
  isshown,
  //   handleClose,
  edit,
  modalcontent,
  data,
  updateChapterById,
  token,
  setEdit,
  setisshown,
  customDispatch,
  afterCreate,
  //   errors,
  //   edit_handleSubmit,
  //   updateDetails,
  //   edit_register,
  //   Controller,
  //   control,
  //   Multiselect,
}) => {
  //   console.log(updateChapterById);
  const {
    register: edit_register,
    handleSubmit: edit_handleSubmit,
    formState: edit_formState,
    reset: editreset,
    formState: { errors: edit_error },
    control: edit_control,
  } = useForm();

  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const handleCloseTwo = () => {
    setisshown(false);
    setEdit(false);
    editreset();
  };
  //   const dispatch = useDispatch();

  //   useEffect(() => {
  //     console.log(updateChapterById);
  //   }, [updateChapterById]);

  const updateDetails = (body) => {
    // console.log("=========");
    // console.log(body);
    let data = {
      name: body.name,
      title: body.title,
      isPublished: body.published,
      permissions: body.userSelect,
    };
    // console.log(data);
    let id = updateChapterById;
    customDispatch(
      data,
      id,
      token,
      handleCloseTwo,
      afterCreate,
      setLoadingUpdate
    );
    // upd(data, customerId?.id, token);
    // editreset();
  };

  const userSelect = data
    ?.filter((item) => item.name != "free")
    .map((item) => item.name);
  //   console.log(userSelect);

  return (
    <Modal
      show={isshown}
      onHide={() => handleCloseTwo()}
      className="Modal tnModal"
    >
      <Modal.Header className="modal-header">
        <Modal.Title>Details</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          onClick={handleCloseTwo}
        >
          &times;
        </button>
      </Modal.Header>
      <Modal.Body>
        {!edit ? (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">
                    <span className="mar-5">
                      <input type="checkbox" name="" id="" />
                    </span>
                    Name
                  </th>
                  <th scope="col">Title</th>
                  <th scope="col">Permission</th>
                  <th scope="col">Published Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span className="mar-5">
                      <input type="checkbox" name="" id="" />
                    </span>
                    {modalcontent?.name ? modalcontent?.name : "None"}
                  </td>
                  <td>{modalcontent?.title ? modalcontent?.title : "None"}</td>
                  <td>{modalcontent?.phone ? modalcontent?.phone : "None"}</td>
                  <td>{modalcontent?.phone ? modalcontent?.phone : "None"}</td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <form onSubmit={edit_handleSubmit(updateDetails)}>
            <div className="col-lg-12">
              <div className="setting">
                <label htmlFor="">Name</label>
                <br />
                <input
                  className="hide_arrows"
                  type="text"
                  placeholder="Enter Name"
                  defaultValue={
                    modalcontent?.name ? modalcontent?.name : "None"
                  }
                  {...edit_register("name", {
                    required: "This Field Is Required",
                    maxLength: 20,
                    minLength: 5,
                  })}
                />
              </div>
            </div>
            <br />
            <div className="col-lg-12">
              <div className="setting">
                <label htmlFor="">Title</label>
                <br />
                <input
                  className="hide_arrows"
                  type="text"
                  placeholder="Enter Title"
                  defaultValue={
                    modalcontent?.title ? modalcontent?.title : "None"
                  }
                  {...edit_register("title", {
                    required: "This Field Is Required",
                    maxLength: 20,
                    minLength: 5,
                  })}
                />
              </div>
            </div>
            <br />
            <div className="col-lg-12">
              <div className="setting">
                <label htmlFor="">Published Status</label>
                <br />
                <input
                  style={{ width: "20px" }}
                  name="published"
                  type="checkbox"
                  // defaultValue={modalcontent?.}
                  {...edit_register("published", {
                    // required: "This Field Is Required",
                    maxLength: 20,
                    minLength: 5,
                  })}
                />
              </div>
            </div>
            <br />
            <div className="col-lg-12">
              <div className="setting">
                <label htmlFor="">Permission</label>
                <br />
                <Controller
                  control={edit_control}
                  name="userSelect"
                  render={({ field: { value, onChange } }) => (
                    <Multiselect
                      options={userSelect}
                      isObject={false}
                      showCheckbox={true}
                      hidePlaceholder={true}
                      closeOnSelect={false}
                      onSelect={onChange}
                      onRemove={onChange}
                      selectedValues={value}
                    />
                  )}
                />
              </div>
            </div>
            {loadingUpdate ? (
              <Button
                className="btn btn-success"
                // type="submit"
                disabled
              >
                Updating
              </Button>
            ) : (
              <Button
                className="btn btn-success"
                // onSubmit={() => handleSubmit(updateDetails)}
                type="submit"
              >
                Update
              </Button>
            )}
          </form>
        )}
      </Modal.Body>
      {/*<ModalFooter>
          <Button
            className="btn btn-success"
            onClick={() => handleSubmit(updateDetails)}
            type="submit"
            >
            Update
          </Button>
        </ModalFooter> */}
    </Modal>
  );
};

export default UpdateChapterModal;
