const api_state = {
  book: [],
  bookmark: []
};

const formBookReducer = (state = api_state, action) => {
  switch (action.type) {
    case "GET_BOOK":
      // console.log(action.payload);
      return {
        ...state,
        book: action.payload,
      };
    case "GET_SEASONS":
      return {
        user: [...action.payload],
      };
    case "ADD_BOOK":
      // console.log(action);
      return {
        ...state,
      };
    // case "UPDATE_BOOK":
    //   console.log(action);
    //   return {
    //     ...state,
    //   };
    case "UPDATE_BOOK":
      // let Index = 0;
      // let copyArray = [...state.book];
      // console.log(Index, copyArray);
      // state?.book?.map((ele, idx) => {
      //   if (ele.id === action.payload.id) {
      //     Index = idx;
      //   }
      // });
      // copyArray[Index] = action.payload;
      return {
        ...state,
        // book: [...copyArray],
      };
    case "DELETE_BOOK":
      return {
        ...state,
        book: state?.book?.filter((item) => item?._id !== action?.payload?.id),
      };
    // const index = state.user.map(item=>item._id===action.payload.id)
    case "GET_BOOKMARK":
      return {
        ...state,
        bookmark: action.payload
      }
    default:
      return state;
  }
};
export default formBookReducer;
