import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/bookActions";
import descriptionReducer from "../../redux/reducers/descrptipnReducer";
import userReducer from "../../redux/reducers/userReducer";

const ChapterDescription = ({
  getAllPara,
  descriptionReducer,
  userReducer,
}) => {
  const params = useParams();
  const id = params.id;
  const token = userReducer.accessToken;

  // console.log(id);

  useEffect(() => {
    getAllPara(id, token);
  }, []);

  // console.log(descriptionReducer?.data.length === 0);

  return (
    <>
      <div className="max-width-800 bookDescpMain">
        <h1 className="text-center mt-5">Description</h1>
        {descriptionReducer?.data?.length === 0 ? (
          <h2 className="text-center mt-5" style={{ color: "red" }}>
            No Data Found
          </h2>
        ) : (
          <>
            <h1 className="text-center mb-5" style={{ fontWeight: "bold" }}>
              {descriptionReducer?.data?.[0]?.content}
            </h1>
            {descriptionReducer?.data?.map((item, index) => {
              if (index === 0) {
              } else {
                return (
                  <p style={{ fontSize: "16px" }} key={index}>
                    {item?.content}
                  </p>
                );
              }
            })}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ descriptionReducer, userReducer }) => {
  // console.log(descriptionReducer);
  return { descriptionReducer, userReducer };
};
export default connect(mapStateToProps, actions)(ChapterDescription);
