const api_state = {
  dashboard: [],
};

const dashboardReducer = (state = api_state, action) => {
  // console.log(action.payload)
  switch (action.type) {
    case "GET_DASHBOARD_DATA":
      return {
        ...state,
        dashboard: action?.payload,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
