import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";

import * as actions from "../../redux/actions/userAction";
import CustomModal from "../Atomics/CustomModal";
import axios from "axios";
import {base_url} from "../../config/config.json"

const UsersList = ({ get_userlists, blockUnblockUser, userReducer }) => {
  const [rowIndex, setRowIndex] = useState();
  const [isEditCatModal, setIsEditCatModal] = useState(false);
  const [page, setpage] = useState(1);
  const token = userReducer.accessToken;
  const value = parseInt(userReducer.userCount / 25);
  const [error, setError] = useState("")
  const [filteredArray, setFilteredArray] = useState([])
  const [isShowFilteredArr, setIsShowFilteredArr] = useState(false)

  const [filteredText, setFilteredText] = useState("")
  const [newFilteredArr, setNewFilteredArr] = useState([]) 

  const settingInput=(e)=>{
    setFilteredText(e.target.value)
  }
  
  useEffect(()=>{
    // setFilteredText(e.target.value)
    setIsShowFilteredArr(true)
    setError("")
    let data = {text:filteredText}

    // const searchValue = new RegExp(e.target.value, 'g');
    const header = {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    };
    if(filteredText.length > 0){

      const getData  =  setTimeout(()  =>  {
        axios.post(`${base_url}/admin/users/findByText`,data, header).then((response)=>{
          if(response.status == 200){
            setNewFilteredArr(response.data)
            console.log(response)
          }else if(response.data.length == 0){
            setError("nothing found")
          }
        })
        }, 2000)
        return () => clearTimeout(getData)
      }
  },[filteredText])

  const showModal = (item) => {
    setIsEditCatModal(true);
    setRowIndex(item);
  };
  var pageCount;

  if (userReducer.userCount == value * 25) {
    pageCount = value;
  } else {
    pageCount = parseInt(userReducer.userCount / 25) + 1;
  }
  useEffect(() => {
    get_userlists(token, 1);
  }, []);
  useEffect(() => {
    get_userlists(token, page);
  }, [page]);
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setpage(selectedPage + 1);
  };
  const clearInput=()=>{
    setIsShowFilteredArr(false);
    setFilteredText("")

  }
  useEffect(() => {
    if(filteredText.length <= 0){
    setIsShowFilteredArr(false)      
    }
  }, [filteredText])
  return (
    <>
      <div className="container-fluid">
        <div className="block-header" />
        <div className="row clearfix">
          <div className="col-12">
            <h1 className="text-center ">User List</h1>
            <div className="table-responsive usersTable max-width-1000">

              <div className="card">
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Search User</label>
                    <input type="text" onChange={(e)=>settingInput(e)} value={filteredText} className="form-control" placeholder="Enter user name or email" />
                    <button onClick={()=>clearInput()} className="" >Clear search</button>
                    <span className="text-danger fw-bold">{error && error}</span>
                  </div>
                  {
                    filteredArray.length ?
                      <div className="text-right">
                        <button className="btn text-danger font-weight-bold" onClick={() => {
                          setFilteredText("")
                          setFilteredArray([])
                        }}>Clear</button>
                      </div>
                      :
                      null
                  }
                </div>
              </div>

              <table className="table header-border table-hover table-custom spacing5">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>User Name</th>
                    <th>Subscription</th>
                    <th>Email</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Orignal array map */}
                  {isShowFilteredArr == false && !filteredArray.length && userReducer?.userlist?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <th>
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                            src={
                              item?.profilePic?.url
                                ? item?.profilePic?.url
                                : "http://static.everypixel.com/ep-pixabay/0329/8099/0858/84037/3298099085884037069-head.png"
                            }
                            alt=""
                          />
                        </th>
                        <td>{item?.username ? item?.username : "None"}</td>
                        <td>
                          {item?.order?.product?.name ? (
                            <button
                              className="btn btn-success"
                              disabled
                              style={{ cursor: "default" }}
                            >
                              {item?.order?.product?.name}
                            </button>
                          ) : (
                            "None"
                          )}
                        </td>
                        <td>{item.email}</td>
                        <td className="text-center">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              // console.log(item);
                              showModal(item);
                            }}
                          >
                            Show
                          </button>
                        </td>
                      </tr>
                    );
                  })}

                  {/* Filtered array map */}
                  {isShowFilteredArr == true  && newFilteredArr.map((item, i) => {
                    return (
                      <tr key={i}>
                        <th>
                          <img
                            style={{
                              width: "50px",
                              height: "50px",
                            }}
                            src={
                              item?.profilePic?.url
                                ? item?.profilePic?.url
                                : "http://static.everypixel.com/ep-pixabay/0329/8099/0858/84037/3298099085884037069-head.png"
                            }
                            alt=""
                          />
                        </th>
                        <td>{item?.username ? item?.username : "None"}</td>
                        <td>
                          {item?.order?.product?.name ? (
                            <button
                              className="btn btn-success"
                              disabled
                              style={{ cursor: "default" }}
                            >
                              {item?.order?.product?.name}
                            </button>
                          ) : (
                            "None"
                          )}
                        </td>
                        <td>{item.email}</td>
                        <td className="text-center">
                          <button
                            className="btn btn-primary"
                            onClick={() => {
                              // console.log(item);
                              showModal(item);
                            }}
                          >
                            Show
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  }
                </tbody>
                <tfoot></tfoot>
              </table>
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </div>
      {isEditCatModal && (
        <div className="modalqa">
          <CustomModal
            size="xl"
            _oncloseModal={() => {
              setIsEditCatModal(false);
            }}
            updation={false}
          >
            <h1 className="mb-4 modalHeading">Details</h1>
            <div className="form-row">
              <div className="col-md-4 mb-3">
                <label htmlFor="validationCustom01">First Name</label>
                <li className="list-group-item">
                  {rowIndex?.firstName ? rowIndex?.firstName : "None"}
                </li>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="validationCustom02">Last Name</label>
                <li className="list-group-item">
                  {rowIndex?.lastName ? rowIndex?.lastName : "None"}
                </li>
              </div>
              <div className="col-md-4 mb-3">
                <label htmlFor="validationCustom02">Email</label>
                <li className="list-group-item">
                  {rowIndex?.email ? rowIndex?.email : "None"}
                </li>
              </div>
            </div>

            <div className="form-row">
              <div className="col-md-4 mb-3">
                <label htmlFor="validationCustom02">Product Name</label>
                <li className="list-group-item">
                  {rowIndex?.order?.product?.name
                    ? rowIndex?.order?.product?.name
                    : "None"}
                </li>
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="validationCustom02">Amount</label>
                <li className="list-group-item">
                  {rowIndex?.order?.amount ? rowIndex?.order?.amount : "None"}
                </li>
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="validationCustom02">Signup from</label>
                {/* {console.log('rowIndex?.order? ',rowIndex?.provider)} */}
                <li className="list-group-item">
                  {rowIndex?.provider ? rowIndex?.provider : "None"}
                </li>
              </div>

              <div className="col-md-4 mb-3 mt-4">
                <img
                  style={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50px",
                  }}
                  src={
                    rowIndex?.profilePic?.url
                      ? rowIndex?.profilePic?.url
                      : "http://static.everypixel.com/ep-pixabay/0329/8099/0858/84037/3298099085884037069-head.png"
                  }
                />
              </div>
            </div>
          </CustomModal>
        </div>
      )}
    </>
  );
};

function mapStateToProps({ userReducer }) {
  return {
    userReducer,
  };
}

export default connect(mapStateToProps, actions)(UsersList);
