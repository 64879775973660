import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Columnchart = ({ data }) => {
  // console.log(data);
  const [series, setSeries] = useState(data);
  // console.log();
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      height: 500,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 0,
      bottom: 0,
      top: 0,
      //   colors: ["#007FFF", "#2d96ff", "#2dd8ff"],
      colors: ["#2E93fA", "#66DA26", "#546E7A", "#E91E63"],
    },
    xaxis: {
      axisBorder: { show: false },
      categories: ["Monthly Report"],
    },
    yaxis: {
      title: {
        text: "Sales Report",
      },
      labels: {
        formatter: (val) => {
          // console.log(`${val}`);
          return `$${val}`;
        },
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => {
          // console.log(`$${val}`);
          return `$${val}`;
        },
      },
    },
  });
  return (
    <ReactApexChart options={options} series={series} type="bar" height={284} />
  );
};

export default Columnchart;
