import React from "react";
import FormValidation from "../../components/Form/formValidation";

function BookForm() {
  return (
    <>
      <FormValidation />
    </>
  );
}

export default BookForm;
