import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import * as actions from "../../redux/actions/bookActions";
import { getPackage } from "../../redux/actions/bookActions";
import userReducer from "../../redux/reducers/userReducer";
import { Button, Modal, ModalBody, ModalFooter, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import { Multiselect } from "multiselect-react-dropdown";
import UpdateChapterModal from "./UpdateChapterModal";
import { toast } from "react-toastify";

const Chapter = ({
  userReducer,
  createChapter,
  chapterReducer,
  getAllChapters,
  updateChapter,
  seasonReducer,
  deleteChapter,
}) => {
  const [name, setName] = useState();
  const param = useParams();
  const history = useHistory();
  const [update, setUpdate] = useState(false);
  const [isshown, setisshown] = useState(false);
  const [modalcontent, setmodalcontent] = useState();
  const [edit, setEdit] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [data, setData] = useState();
  const [isshownTwo, setisshownTwo] = useState(false);
  const [isshownThree, setisshownThree] = useState(false)
  const [modalcontentTwo, setmodalcontentTwo] = useState();
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0)
  const [endingChapter, setEndingChapter] = useState(0)
  const [startingChapter, setStartingChapter] = useState(0)
  const [chapterName, setChapterName] = useState('')
  const [quantity, setQuantity] = useState(0)
  const {
    register,
    handleSubmit,
    formState,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const dispatch = useDispatch();

  const params = useParams();
  let bookId = params.bookId;
  let seasonId = params.seasonId;
  const token = userReducer?.accessToken;
  // let seasonId = seasonReducer?.season;

  const rootCauseAnalysisCategorys = data
    ?.filter((item) => item.name != "free")
    .map((item) => item.name);

  useEffect(() => {
    getAllChapters(param.seasonId, param.bookId, token);
  }, []);

  const afterCreate = (handleCloseTwo) => {
    getAllChapters(param.seasonId, param.bookId, token);
    // console.log(handleCloseTwo);
    handleCloseTwo();
  };
  const afterCreateTwo = () => {
    getAllChapters(param.seasonId, param.bookId, token);
  };
  const onSubmit = () => {
    // const Re= `/Chapter (?:[1234567890])/-title(?:[1234567890])/`
    // console.log(name.search(Re));

    let createdData = {
      chapter: name.trim(),
      book: param.bookId.trim(),
      season: param.seasonId.trim(),
    };
    // var mystr1 = createdData.chapter.trim()
    // var mystr2 = createdData.book.trim()
    // var mystr3 = createdData.season.trim()
    createChapter(createdData, token).then(afterCreateTwo);
  };


  const getPackage = async () => {
    try {
      const response = await axios.get(
        `http://192.168.0.50:8002/api/admin/packages/gets`
      );
      // console.log(response?.data);
      setData(response?.data?.data);
    } catch (err) {
      // console.log(err);
    }
  };

  const showDetails = (id) => {
    history.push(`/chapterdescription/${id}`);
  };
  const editItem = (item) => {
    // console.log(item);
    setmodalcontent(item);
    setisshown(true);
    setEdit(true);
    setCustomerId(item);
    getPackage();
  };
  const handleClose = () => {
    setisshown(false);
    setEdit(false);
    reset();
  };
  const handleCloseTwo = () => {
    setisshownTwo(false);
    setEdit(false);
    reset();
  };

  const updateChapterData = () => {
    const editData = {
      chapter: name,
      book: param?.bookId,
      season: param?.seasonId,
    };
    updateChapter(editData, token);
    setName("");
  };


  const secondModalContent = () => {
    getPackage();
    setisshownTwo(true);
  };
  // console.log(customerId);

  const onSuccess = () => {
    getAllChapters();
  };

  const publishChapter = (body) => {
    // console.log(body);
    let data = {
      book: bookId,
      chapter: body.chapter,
      season: seasonId,
      permissions: [],
      index: index,
    };
    // console.log(data);
    actions
      .createASingleChapter(data, token, setLoading)
      .then(afterCreateTwo)
      .then(() => {
        handleClose();
        reset();
        setEdit(false);
        setisshown(false);
      });
  };

  const createMultiCapters = () => {
    
    let quantity = endingChapter - startingChapter +1;
    if(quantity > 0){

      for (let i = 0; i < quantity; i++) {
        setTimeout(function() {
          let newIndex = startingChapter+i;
          console.log(typeof index)
          let data = {
            book: bookId,
            chapter: `Chapter ${newIndex}`,
            season: seasonId,
            permissions: [],
            index: newIndex,
          };
          
          // console.log(data);
          actions
          .createASingleChapter(data, token, setLoading)
          .then(afterCreateTwo)
          .then(() => {
            handleClose();
            reset();
            setEdit(false);
            setisshown(false);
          });
        }, i * 400);
        
      }}
  }

  const deleteItem = (item) => {
    let id = item?._id;
    deleteChapter(id, token);
  };

  let updateChapterById = customerId?._id;

  const customDispatch = async (
    data,
    id,
    token,
    handleClose,
    afterCreate,
    setLoadingUpdate
  ) => {
    updateChapter(data, id, token, handleClose, afterCreate, setLoadingUpdate);
  };
  const sortedChapter = chapterReducer?.chapters.sort((a, b) => a.index - b.index)
  return (
    <>
      <div className="max-width-800">
        <div className="create_btn mt-3 d-flex justify-content-end ">
          <button
            className="btn btn-success"
            onClick={() => {
              setisshownTwo(true);
              getPackage();
            }}
          >
            Create Chapter
          </button>
          <button
            className="btn btn-success ml-3"
            onClick={() => {
              setisshownThree(true);
              getPackage();
            }}
          >
            Create Bulk Chapters
          </button>
        </div>
        <div className="mt-5 mb-2">
          <div className="tableHead-withBtns ">
            <h2 className="">Chapter's List</h2>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Title</th>
              <th scope="col">Created At</th>
              <th scope="col">Edit</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          {!chapterReducer?.chapters?.length ? (
            <thead>
              <tr>
                <th scope="row">Data Not Recorded</th>
                <th scope="col">Data Not Recorded</th>
                <th scope="col">Data Not Recorded</th>
                <th scope="col">Data Not Recorded</th>
                <th scope="col">Data Not Recorded</th>
              </tr>
            </thead>
          ) : (
            sortedChapter?.map((item, index) => {
              return (
                <tbody key={index}>
                  <tr>
                    <th>
                      <button
                        className="btn btn-success chpNameBtn"
                        onClick={() => showDetails(item?._id)}
                      >
                        {" "}
                        {item?.name}{" "}
                      </button>
                    </th>
                    <td scope="col">{item?.title}</td>
                    <td scope="col">{item?.createdAt}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => editItem(item)}
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteItem(item)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              );
            })
          )}
        </table>

        <UpdateChapterModal
          isshown={isshown}
          handleClose={handleClose}
          edit={edit}
          modalcontent={modalcontent}
          control={control}
          data={data}
          updateChapterById={updateChapterById}
          token={token}
          setEdit={setEdit}
          setisshown={setisshown}
          customDispatch={customDispatch}
          afterCreate={afterCreate}
        />

        <Modal
          show={isshownTwo}
          onHide={() => secondModalContent()}
          className="Modal tnModal"
        >
          <Modal.Header className="modal-header">
            <Modal.Title>Create Chapter</Modal.Title>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={() => handleCloseTwo()}
            >
              &times;
            </button>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={publishChapter}>
              <div className="row">
                <br />
                <div className="col-md-12 mb-2">
                  <label htmlFor="">Chapter</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="text"
                    {...register("chapter", {
                      // required: "This Field Is Required",
                      // maxLength: 40,
                      // minLength: 4,
                    })}
                  />
                </div>
                <div className="col-md-12 mb-2">
                  <label htmlFor="">Index</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="index"
                    value={index}
                    onChange={(e) => setIndex(e.target.value)}
                  />
                </div>
                <div className="col-lg-12">
                  <div className="setting">
                    <label htmlFor="">Permission</label>
                    <br />
                    <Controller
                      control={control}
                      name="rootCauseAnalysisCategory"
                      render={({ field: { value, onChange } }) => (
                        <Multiselect
                          options={rootCauseAnalysisCategorys}
                          isObject={false}
                          showCheckbox={true}
                          hidePlaceholder={true}
                          closeOnSelect={false}
                          onSelect={onChange}
                          onRemove={onChange}
                          selectedValues={value}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <ModalFooter>
            {loading ? (
              <Button className="btn btn-success" disabled>
                Creating
              </Button>
            ) : (
              <Button
                className="btn btn-success"
                onClick={handleSubmit(publishChapter)}
                type="submit"
              >
                Create
              </Button>
            )}
          </ModalFooter>
        </Modal>
        {/* bulk create modal */}
        <Modal show={isshownThree} onHide={() => setisshownThree(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Create Bulk Chapters</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Chapter Name</Form.Label>
                <input onChange={(e) => setChapterName(e.target.value)} value={chapterName} />
              </Form.Group> */}
              
                <Form.Label className="font-weight-bold">Quantity</Form.Label>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="">Ending Chapter</Form.Label>

                <input type='number' className='w-25' onChange={(e)=> setStartingChapter(parseInt(e.target.value))} value={startingChapter} />
                <Form.Label>Ending Chapter</Form.Label>
                <input type='number' className='w-25' onChange={(e)=> setEndingChapter(parseInt(e.target.value))} value={endingChapter} />
              </Form.Group>
              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success text-lg" onClick={() => createMultiCapters()} >
              Create
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </>
  );
};

const mapStateToProps = ({ chapterReducer, userReducer, seasonReducer }) => {
  return { chapterReducer, userReducer, seasonReducer };
};

export default connect(mapStateToProps, actions)(Chapter);
