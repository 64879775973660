import React, { useEffect, useState } from 'react';
// import { Link } from "react-router-dom";
import Columnchart from '../common/columnchart';
// import Tooltip from "../common/toolTip";
import { useSelector, useDispatch } from 'react-redux';
import { getDashboardData } from '../../redux/actions/bookActions';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import './styles.css';

const Dashboard = () => {
  const [isBlocked, setIsBlocked] = useState(false);
  const dispatch = useDispatch();

  const token = useSelector((state) => state?.userReducer?.accessToken);
  // console.log(token);
  const dashboardData = useSelector(
    (state) => state?.dashboardReducer?.dashboard
  );

  useEffect(() => {
    dispatch(getDashboardData(token));
  }, []);

  let cards = [
    {
      title: 'Total Users',
      quantity: `${dashboardData?.data?.users}`,
      icon: 'fa fa-users',
      iconBgCLr: 'bg-indigo',
    },
    {
      title: 'Total Subscriptions',
      quantity: `${dashboardData?.data?.orders}`,
      icon: 'fa fa-users',
      iconBgCLr: 'bg-indigo',
    },
    {
      title: 'Total Sales',
      quantity: `$${dashboardData?.data?.totalSales}`,
      icon: 'fa fa-users',
      iconBgCLr: 'bg-indigo',
    },
  ];
  const header = {
    headers: {
      Authorization: 'Bearer ' + token,
      Accept: 'application/json',
    },
  };
  useEffect(() => {
    const sendReq = async () => {
      const resp = await axios.get(
        'https://api.csnovels.com/configuration/getConfig',
        header
      );
      console.log('Somethign', resp);
      if (resp.status === 200) {
        setIsBlocked(resp?.data?.config?.isBlock);
      }
    };
    sendReq();
  }, []);
  useEffect(() => {
    console.log(isBlocked);
  }, [isBlocked]);
  const updateConfig = async () => {
    const response = await axios.post(
      'https://api.csnovels.com/configuration/updateConfig',
      { isBlock: !isBlocked }
    );
    console.log('UPDATED', response);
    if (response.status === 200) {
      setIsBlocked(response?.data?.config?.isBlock);
    }
  };

  return (
    <div className="container-fluid">
      <div className="block-header" />
      <Dropdown className="ml-0">
        <Dropdown.Toggle
          variant="success"
          id="dropdown-basic"
          className="user-name left_dropdown_btn"
        >
          <button className="main-button">mobile ad switch</button>
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ background: '#F1F4F6' }}>
          <p className={isBlocked ? 'active-text' : 'inactive-text'}>
            {isBlocked ? 'Mobile ad is on' : 'Mobile ad is off'}
          </p>
          <li className="divider" />
          <button className="dropdown-item" onClick={() => updateConfig()}>
            <i className="icon-power "></i>{' '}
            {isBlocked ? ' turn off' : ' turn on'}
          </button>

          {/* </Link> */}
        </Dropdown.Menu>
      </Dropdown>
      <div className="row clearfix jusCent-mob">
        {cards.map((item, i) => {
          return (
            <div key={i} className="col-lg-4 col-md-6 col-sm-6">
              <div className="card dashTopCards">
                <div className="body">
                  <div className="d-flex align-items-center">
                    <div
                      className={`icon-in-bg ${item.iconBgCLr} text-white rounded-circle`}
                    >
                      <i className={item.icon}></i>
                    </div>
                    <div className="ml-4">
                      <span>{item.title}</span>
                      <h4 className="mb-0 font-weight-medium dash-quan">
                        {item.quantity}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="row clearfix">
        <div className="col-lg-12 col-md-12">
          <div className="card user_statistics">
            <div className="header">
              <h2>Subscriptions Report</h2>
            </div>
            <div className="body">
              <Columnchart data={dashboardData?.data?.salesReport} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
