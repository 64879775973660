const api_state = {
  accessToken: "",
  userData: null,
  userlist: [],
  userCount: "",
  packageData: [],
};

const userReducer = (state = api_state, action) => {
  switch (action.type) {
    case "GET_USERLISTS":
      return {
        ...state,
        userlist: [...action.payload.data],
        userCount: action.payload.totalData,
      };
    case "USER_LOGIN":
      // console.log(action.payload.token);
      return {
        ...state,
        userData: action.payload,
        accessToken: action.payload.token,
      };
    // case "PACKAGE_DATA":
    //   console.log(action.payload);
    //   return {
    //     ...state,
    //     // packageData:[]
    //   };
    default:
      return state;
  }
};

export default userReducer;
