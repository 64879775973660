const api_state = {
  categories: [],
};

const categoriesReducer = (state = api_state, action) => {
  // console.log(action.payload, '====== get category data =======')

  switch (action.type) {
    case "GET_CATEGORIES":
      // console.log(action.payload);
      return {
        ...state,
        categories: action.payload,
      };
    case "DELETE_CATEGORIES":
      // console.log(action.payload);
      return {
        ...state,
        categories: state?.categories?.filter(
          (item) => item?._id !== action.payload
        ),
      };
    default:
      return state;
  }
};

export default categoriesReducer;
