import React, { useState } from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import * as actions from "../../redux/actions/bookActions";
import { changePassword } from "../../redux/actions/userAction";

const PageProfile = ({ userReducer }) => {
  const [oldPassowrd, setoldPassowrd] = useState("");
  const [newPassword, setnewPassword] = useState("");

  const [confirmPassword, setconfirmPassword] = useState("");
  const updatePass = () => {
    changePassword(
      userReducer.accessToken,
      oldPassowrd,
      newPassword,
      confirmPassword
    );
    resetpass();
  };
  const resetpass = () => {
    // console.log("Muni");
    setoldPassowrd("");
    setnewPassword("");
    setconfirmPassword("");
  };

  // let userInfo = userReducer?.userData;
  // console.log(userInfo);

  return (
    <>
      <div className="container-fluid">
        <div className="block-header">
          <div className="row clearfix">
            <div className="col-md-6 col-sm-12">
              <h2>User Profile</h2>
              <nav aria-label="breadcrumb">
                {/* <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/da">Oculux</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/">Pages</a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    User Profile
                  </li>
                </ol> */}
              </nav>
            </div>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-xl-4 col-lg-4 col-md-5">
            <div className="card">
              <div className="header">
                <h2>Information</h2>
              </div>
              <div className="body">
                {/* <small className="text-muted">Address: </small>
                <p>795 Folsom Ave, Suite 600 San Francisco, 94107</p>
                <hr /> */}
                <small className="text-muted">User Name: </small>
                <p>{userReducer?.userData?.username}</p>
                <hr />
                <small className="text-muted">Email address: </small>
                <p>{userReducer.userData?.email}</p>
                {/* <hr />
                <small className="text-muted">Mobile: </small>
                <p>+ 202-222-2121</p>
                <hr />
                <small className="text-muted">Birth Date: </small>
                <p className="m-b-0">October 17th, 93</p>
                <hr />
                <small className="text-muted">Social: </small>
                <p>
                  <i className="fa fa-twitter m-r-5"></i> twitter.com/example
                </p>
                <p>
                  <i className="fa fa-facebook  m-r-5"></i> facebook.com/example
                </p>
                <p>
                  <i className="fa fa-github m-r-5"></i> github.com/example
                </p>
                <p>
                  <i className="fa fa-instagram m-r-5"></i>{" "}
                  instagram.com/example
                </p> */}
              </div>
            </div>
          </div>

          <div className="col-xl-8 col-lg-8 col-md-7">
            <div className="card">
              <div className="header">
                <h2>Account Data</h2>
              </div>
              <div className="body">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12">
                    <hr />
                    <h6>Change Password</h6>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Current Password"
                        value={oldPassowrd}
                        onChange={(e) => setoldPassowrd(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="New Password"
                        value={newPassword}
                        onChange={(e) => setnewPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Confirm New Password"
                        value={confirmPassword}
                        onChange={(e) => setconfirmPassword(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="btn btn-round btn-primary mr-1"
                  onClick={() => updatePass()}
                >
                  Save
                </button>{" "}
                &nbsp;&nbsp;
                <button
                  type="button"
                  className="btn btn-round btn-default"
                  onClick={() => {
                    resetpass();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ userReducer }) => {
  return { userReducer };
};
export default connect(mapStateToProps, actions)(PageProfile);
