import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Menu from "./Menu";
import Header from "./Header";
import ThemeSetting from "./ThemeSetting";
import Searchbar from "./Searchbar";
import Rightbar from "./Rightbar";
import Megamenu from "./Megamenu";
import Routes from "../Route";
import { Redirect } from "react-router-dom";
import Login from "../Authentication/login";
import userReducer from "../../redux/reducers/userReducer";
import { connect } from "react-redux";

class Layout extends Component {
  render() {
    let counter = this.props.userReducer;
    // console.log("here", counter);
    this.state = {
      accessToken: counter.accessToken || false,
    };

    // console.log(this.state.accessToken);
    if (this.state.accessToken) {
      return (
        <>
          <ThemeSetting />
          <div className="overlay" />
          <div id="wrapper">
            <Header />
            {/* <Searchbar /> */}
            <Megamenu />
            <Rightbar />
            <Menu {...this.props} />
            <div id="main-content">
              {/* <Switch> */}
              {Routes.map((layout, i) => {
                // console.log(layout, i);
                return (
                  <Route
                    key={`r${i}`}
                    exact={layout.exact}
                    path={layout.path}
                    component={layout.component}
                  ></Route>
                );
              })}
              {/* </Switch> */}
            </div>
          </div>
        </>
      );
    } else {
      return <Redirect to="/login"/>
    }
  }
}

function mapStateToProps({ userReducer }) {
  return {
    userReducer,
  };
}

export default connect(mapStateToProps)(Layout);
