import React, { useEffect, useState } from "react";
import * as actions from "../../redux/actions/bookActions";
import { connect } from "react-redux";
import CustomModal from "../Atomics/CustomModal";
import userReducer from "../../redux/reducers/userReducer";
import ReactPaginate from "react-paginate";

const Order = ({ getOrderList, orderReducer, userReducer }) => {
  const [isEditCatModal, setIsEditCatModal] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const value = parseInt(orderReducer.orderCount / 25);
  var pageCount;
  if (orderReducer.orderCount == value * 25) {
    pageCount = value;
  } else {
    pageCount = parseInt(orderReducer.orderCount / 25) + 1;
  }

  const [page, setpage] = useState(1);
  const token = userReducer.accessToken;

  useEffect(() => {
    getOrderList(token, 1);
  }, []);

  useEffect(() => {
    getOrderList(token, page);
    // console.log(page);
  }, [page]);

  const showModal = (index) => {
    setIsEditCatModal(true);
    setRowIndex(index);
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    setpage(selectedPage + 1);
  };
  // console.log(orderReducer?.order?.[rowIndex]?.user?.profilePic?.url);
  return (
    <>
      <div className="max-width-800">
        <h2 className=" mt-5 mb-5">List Of Orders</h2>
        <table className="table ">
          <thead>
            <tr>
              <th scope="col" style={{ fontWeight: "bold" }}>
                Profile Image
              </th>
              <th scope="col" style={{ fontWeight: "bold" }}>
                User Name
              </th>
              <th scope="col" style={{ fontWeight: "bold" }}>
                Product
              </th>
              <th scope="col" style={{ fontWeight: "bold" }}>
                Active
              </th>
              <th scope="col" style={{ fontWeight: "bold" }}>
                Amount
              </th>
              <th scope="col" style={{ fontWeight: "bold" }}>
                Show Details
              </th>
            </tr>
          </thead>
          {orderReducer?.order?.map((item, index) => {
            // console.log(item);
            return (
              <tbody key={index}>
                <tr>
                  <td>
                    <img
                      src={
                        item?.user?.profilePic?.url
                          ? item?.user?.profilePic?.url
                          : "https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659652_960_720.png"
                      }
                      alt="Profile Image"
                    />
                  </td>
                  <th scope="row" key={index}>
                    {item?.user?.username ? item?.user?.username : "None"}
                  </th>
                  <td>{item?.product?.name ? item?.product?.name : "None"}</td>
                  <td>{item?.active.toString()}</td>
                  <td>{item?.amount}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => showModal(index)}
                    >
                      Show
                    </button>
                  </td>
                </tr>
              </tbody>
            );
          })}
          <tfoot></tfoot>
        </table>

        <ReactPaginate
          previousLabel={"prev"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>

      {isEditCatModal && (
        <CustomModal
          size="xl"
          _oncloseModal={() => {
            setIsEditCatModal(false);
          }}
          updation={false}
        >
          <h1 className="mb-5">Details</h1>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label htmlFor="validationCustom01">Product Name</label>
              <li className="list-group-item">
                {orderReducer?.order?.[rowIndex]?.product?.name
                  ? orderReducer?.order?.[rowIndex]?.product?.name
                  : "None"}
              </li>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="validationCustom02">Extra Amount</label>
              <li className="list-group-item">
                {orderReducer?.order?.[rowIndex]?.amount
                  ? orderReducer?.order?.[rowIndex]?.amount
                  : "None"}
              </li>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="validationCustom02">Product Amount</label>
              <li className="list-group-item">
                {orderReducer?.order?.[rowIndex]?.product?.amount
                  ? orderReducer?.order?.[rowIndex]?.product?.amount
                  : "None"}
              </li>
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label htmlFor="validationCustom01">Product Status</label>
              <li className="list-group-item">
                {orderReducer?.order?.[rowIndex]?.product?.status
                  ? orderReducer?.order?.[rowIndex]?.product?.status
                  : "None"}
              </li>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="validationCustom02">Status</label>
              <li className="list-group-item">
                {orderReducer?.order?.[rowIndex]?.status
                  ? orderReducer?.order?.[rowIndex]?.status
                  : "None"}
              </li>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="validationCustom02">User Email</label>
              <li className="list-group-item">
                {orderReducer?.order?.[rowIndex]?.user?.email
                  ? orderReducer?.order?.[rowIndex]?.user?.email
                  : "None"}
              </li>
            </div>
          </div>

          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label htmlFor="validationCustom01">User name</label>
              <li className="list-group-item">
                {orderReducer?.order?.[rowIndex]?.user?.username
                  ? orderReducer?.order?.[rowIndex]?.user?.username
                  : "None"}
              </li>
            </div>
            <div className="col-md-4 mb-3 mt-4">
              <img
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50px",
                }}
                src={
                  orderReducer?.order?.[rowIndex]?.user?.profilePic?.url
                    ? orderReducer?.order?.[rowIndex]?.user?.profilePic?.url
                    : "https://cdn.pixabay.com/photo/2015/03/04/22/35/avatar-659652_960_720.png"
                }
                alt=""
              />
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
};

const mapStateToProps = ({ orderReducer, userReducer }) => {
  // console.log(orderReducer);
  return { orderReducer, userReducer };
};

export default connect(mapStateToProps, actions)(Order);
