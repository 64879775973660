const api_state = {
  season: [],
};

const seasonReducer = (state = api_state, action) => {
  // console.log(action.payload)
  switch (action.type) {
    case "GET_SEASONS":
      return {
        ...state,

        season: [...action.payload],
      };
    case "UPDATE_SEASON":
      let arr = [...state.season];
      return {
        ...state,
        season: arr,
      };
    case "DELETE_SEASON":
      // console.log(action);
      // console.log(state);
      return {
        ...state,
        season: state?.season?.filter((item) => item?._id !== action?.payload),
      };
    default:
      return state;
  }
};
export default seasonReducer;
