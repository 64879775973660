import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import userReducer from "../../redux/reducers/userReducer";
import * as actions from "../../redux/actions/bookActions";
import { img_url } from "../../config/config.json";
import CustomModal from "../Atomics/CustomModal";
import { Modal } from "react-bootstrap";
import axios from "axios";
import config from "../../config/config.json";
import { MdSync } from "react-icons/md";

const FormValidation = ({
  userReducer,
  formBookReducer,
  getBook,
  get_categories,
  categoriesReducer,
  update_book,
  deleteBook,
  uploadBookImage,
  createBook,
}) => {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [folderId, setFolderId] = useState("");
  const [access, setAccess] = useState("paid");
  const [image, setImage] = useState(false);
  const [id, setId] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [status, setStatus] = useState("ongoing");
  const [selectedImage, setSelectedImage] = useState(
    formBookReducer?.book?.Cover?.url
  );
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedAccess, setSelectedAccess] = useState('paid');
  const [update, setUpdate] = useState(false);
  const [uploadstatus, setuploadstatus] = useState(true);
  const [releaseDate, setreleaseDate] = useState("");
  const history = useHistory();

  let token = userReducer?.accessToken;
  const onSuccess = () => {
    getBook(token);
  };

  useEffect(() => {
    get_categories(token);
    getBook(token);
  }, []);

  useEffect(() => {
    if (categoriesReducer?.user?.length > 0) {
      setSelectedCategory(categoriesReducer?.user[0]?._id);
      if (formBookReducer?.user?.length > 0) {
        setSelectedAccess(formBookReducer?.user[0]?.Access);
      }
    }
  }, [categoriesReducer?.user, formBookReducer?.user]);

  const editItem = (item) => {
    console.log(item , "iteemmmmmm")
    setisopen(true);
    setUpdate(true);
    setId(item?._id);
    setTitle(item?.Title);
    setDescription(item?.Description);
    setFolderId(item?.FolderID);
    setuploadstatus(true);
    setSelectedCategory(item.category._id);
    setSelectedAccess(item.Access);
    setStatus(item?.releaseSchedule !== "completed" ? "ongoing" : "completed");
  };
  const deleteItem = (id) => {
    deleteBook(id, userReducer.accessToken);
    setisopen(false);
  };

  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const onCreateBook = () => {
    getBook();
  };

  const addBook = async (e) => {
    e.preventDefault();
    try {
      const response = await uploadBookImage(selectedImage, token);
      let newSelectedAccess;
      if(selectedAccess == "paid"){
        newSelectedAccess = "fa;se"
      }else{
        newSelectedAccess = "true"
      }
      let bookData = {
        Title: title,
        category: selectedCategory,
        Access: newSelectedAccess  ,
        FolderID: folderId,
        Description: description,
        profileImage: selectedImage?.name,
        Cover: response,
        releaseSchedule: status,
      };
      createBook(bookData, token, getBook);
      setisopen(false);
    } catch (error) {
      toast.error(error.message || error.msg, {
        autoClose: 5000,
      });
    }
    setCategory("");
    setAccess("");
    setImage("");
    setSelectedImage("");
    setreleaseDate("");
    setTitle("");
    setDescription("");
    setuploadstatus(true);
  };

  const updateBook = async (e) => {
    e.preventDefault();
    const response = await uploadBookImage(selectedImage);
    if (
      title.length > 0 &&
      (category.length > 0 || selectedImage?.name) &&
      (folderId.length > 0 || access) &&
      description.length > 0
    ) {
      const editBookData = {
        Title: title,
        Cover: response,
        Description: description,
        category: selectedCategory,
        Access: access,
        releaseSchedule: status,
        // FolderID: folderId,
        // profileImage: selectedImage?.name,
      };
      update_book(id, editBookData, token).then(onSuccess);
      setisopen(false);
      setTitle("");
      setSelectedCategory("");
      setSelectedImage("");
      setFolderId("");
      setAccess("");
      setDescription("");
    } else return toast.error("Cannot Allow Empty Fields");
  };

  // console.log(formBookReducer);
  // console.log(userReducer);
  const [isopen, setisopen] = useState(false);
  const closeModal = () => {
    setisopen(false);
    setTitle("");
    setSelectedCategory("");
    // setSelectedImage("");
    setFolderId("");
    setAccess("");
    setUpdate(false);
    setDescription("");
  };

  const [isLoaded, setIsloaded] = useState(false);

  // Resync seasons or missing seasons in a book
  const ResyncSeasons = async (book) => {
    // console.log(token, "tpken");

    setIsloaded(true);

    const data = {
      book: book._id,
      access: book.Access,
    };

    const header = {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    };

    try {

      const { data: response } = await axios.post(
        `${config.base_url}/admin/season/getMissingSeason/${book.FolderID}`,
        data,
        header
      );

      toast.success(response.msg)

    } catch (error) {

      toast.success("Something went wrong")

    }

    setIsloaded(false);
  };

// Resync Last two seasons of a book
  const ResyncChapters = async (book) => {
    // console.log(token, "tpken");

    setIsloaded(true);

    const data = {
      book: book._id,
      access: book.Access,
    };

    const header = {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    };

    try {

      const { data: response } = await axios.post(
        `${config.base_url}/admin/season/getSeason/${book.FolderID}`,
        data,
        header
      );

      toast.success(response.msg)

    } catch (error) {

      toast.success("Something went wrong")

    }

    setIsloaded(false);
  };

  
  // Resync ALl seasons present inside a google drive folder/book
  const ResyncAllSeasons = async (book) => {
    // console.log(token, "tpken");

    const finishedBooksFolderId = "1Gz-0s8dVWareY45gFhX1kGA0sPrx3Gnv"

    setIsloaded(true);

    const data = {
      book: book._id,
      access: book.Access,
    };

    const header = {
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
      },
    };

    try {

      const { data: response } = await axios.post(
        `${config.base_url}/admin/season/updateAll/${finishedBooksFolderId}`,
        data,
        header
      );

      toast.success(response.msg)

    } catch (error) {

      toast.success("Something went wrong")

    }

    setIsloaded(false);
  };

  return (
    <>
      <div className="col-lg-12">
        <div className="d-flex justify-content-between align-items-center tableHead-withBtns max-width-1200">
          <div>
            <h2 className="user_heading my-4">Books</h2>
          </div>
          <div>
            <button
              className="btn btn-info "
              onClick={ResyncAllSeasons}
            >
             <MdSync /> Resync All
            </button>
            &nbsp;
            <button
              className="btn btn-primary custom-btn" 
              onClick={() => {
                setisopen(true);
              }}
            >
              Add Book
            </button>
          </div>
        </div>
      </div>

      {isopen && (
        <>
          <Modal show={isopen} onHide={() => closeModal()} className="Modal">
            <Modal.Header className="modal-header">
              <Modal.Title className="modal-title">
                {/* {!modalcontent.amount ? "Choose Amount" : ""} */}
              </Modal.Title>

              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={closeModal}
              >
                &times;
              </button>
            </Modal.Header>

            <Modal.Body>
              <div className="row">
                <div className="col-12">
                  <h2>Create A Book</h2>
                </div>
              </div>

              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="pl-3 text-dark">Title</label>
                      <input
                        type="name"
                        className="form-control"
                        placeholder="Search By Title"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="pl-3 text-dark">Category</label>

                      <select
                        className="form-control"
                        value={selectedCategory}
                        onChange={(e) => setSelectedCategory(e.target.value)}
                      >
                        <option value="">Not Selected</option>
                        {categoriesReducer?.categories.map((item, index) => {
                          return (
                            <option key={index} value={item?._id}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label className="pl-3 text-dark">Access</label>

                      <input
                        type="text"
                        placeholder="Access"
                        onChange={(e) => setAccess(e.target.value)}
                        value={access}
                      />
                    </div>
                  </div> */}
                  {/* <div className="col-md-6">
                    <div className="form-group">
                      <label className="pl-3 text-dark">Release Date</label>

                      <input
                        type="date"
                        placeholder="Release Date"
                        onChange={(e) => setreleaseDate(e.target.value)}
                        value={releaseDate}
                      />
                    </div>
                  </div> */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="pl-3 text-dark">Description</label>
                      <textarea
                        className="form-control"
                        placeholder="Description"
                        rows="4"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      ></textarea>
                    </div>
                  </div>
                  {update ? null : (
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="pl-3 text-dark">Folder ID</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Folder ID"
                          onChange={(e) => setFolderId(e.target.value)}
                          value={folderId}
                        />
                      </div>
                    </div>
                  )}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="pl-3 text-dark">Access</label>
                      <select
                        className="form-control"
                        onChange={(e) => setSelectedAccess(e.target.value)}
                        value={selectedAccess}
                      >
                        <option value="paid">Paid</option>
                        <option value="free">Free</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="pl-3 text-dark">Status</label>
                      <select
                        className="form-control"
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                      >
                        <option value="ongoing">Ongoing</option>
                        <option value="completed">Completed</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="App">
                      <h4 style={{ color: "red" }}>Image Upload</h4>

                      <p className="title" style={{ color: "red" }}>
                        Select Image:
                      </p>
                      <div>
                        <input
                          style={{ marginBottom: "20px" }}
                          accept=""
                          type="file"
                          onChange={imageChange}
                        />

                        {!showImage ? (
                          <>
                            {selectedImage && (
                              <div className="afterUpload">
                                <img
                                  className="uploadImage mb-2"
                                  src={
                                    formBookReducer?.book?.Cover?.url
                                      ? formBookReducer?.book?.Cover?.url
                                      : URL.createObjectURL(selectedImage)
                                  }
                                  alt="Thumb"
                                />
                              </div>
                            )}
                          </>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <br />
                </>

                {update ? (
                  <button className="btn btn-success" onClick={updateBook}>
                    Update
                  </button>
                ) : (
                  <button className="btn btn-primary mt-3" onClick={addBook}>
                    Create Book
                  </button>
                )}
                <br />
              </form>
            </Modal.Body>
          </Modal>
        </>
      )}

      <div className="table-responsive bookFormTable max-width-1200">
        <table className="table  ">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Category</th>
              <th scope="col">Description</th>
              <th scope="col">Folder ID</th>
              <th scope="col" className="bookImg-th">
                Book Image
              </th>
              <th scope="col" colSpan={3}>
                Edit Book
              </th>
              {/* <th scope="col">Delete Book</th> */}
            </tr>
          </thead>
          {!formBookReducer?.book?.length ? (
            <tbody>
              <tr>
                <th scope="row">Data Not Recorded</th>
                <td>Data Not Recorded</td>
                <td className="desc">Data Not Recorded</td>
                <td>Data Not Recorded</td>
                <td>Data Not Recorded</td>
                <td>Data Not Recorded</td>
                {/* <td>Data Not Recorded</td> */}
              </tr>
            </tbody>
          ) : (
            formBookReducer?.book?.map((item, index) => {
              // console.log(item);
              return (
                <tbody key={index}>
                  <tr>
                    <th
                      style={{ cursor: "pointer" }}
                      scope="row"
                      onClick={() => {
                        history.push(`/seasonlist/${item?._id}`);
                      }}
                    >
                      <button className="btn btn-success bookNameBtn">
                        {item?.Title}
                      </button>
                    </th>
                    <td>{item?.category?.name}</td>
                    <td className="desc">{item?.Description}</td>
                    <td>{item?.FolderID}</td>
                    <td className="bookTab-profImg">
                      <img src={`${item?.Cover?.url}`} alt="profileImage" />
                    </td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => editItem(item)}
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteItem(item?._id)}
                      >
                        Delete
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-secondary"
                        onClick={() => ResyncSeasons(item)}
                      >
                        <MdSync /> Seasons
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-dark "
                        onClick={() => ResyncChapters(item)}
                      >
                        <MdSync /> Chapters
                      </button>
                    </td>
                  </tr>
                </tbody>
              );
            })
          )}
        </table>
      </div>

      {isLoaded === true ? (
        <div className="UploadLoaderMain">
          <p className="d-flex align-items-center">
            <span> Resyncing Book Please wait </span>
            <span className="dots"></span>
            <span className="dots"></span>
            <span className="dots"></span>
          </p>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

const mapStateToProps = ({
  userReducer,
  categoriesReducer,
  formBookReducer,
}) => {
  return { categoriesReducer, formBookReducer, userReducer };
};

export default connect(mapStateToProps, actions)(FormValidation);
