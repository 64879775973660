const INITIAL_STATE = {
  chapters: [],
};
const chapterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "GET_ALL_CHAPTERS":
      // console.log(action.payload);
      return {
        ...state,
        chapters: action.payload,
      };
    case "CHAPTER_UPDATED":
      // console.log(action.payload);
      let Index = 0;
      let copyArray = [...state?.chapters];
      // state.chapters.map((item, index) => console.log(item._id));
      // state?.chapters?.map((ele, idx) => {
      //    if (ele._id === action.payload.id) {
      //     Index = idx;
      //   }
      // });
      state.chapters.map((item, index) => {
        if (item._id === action.payload.id) {
          Index = index;
        }
      });
      copyArray[Index] = action.payload;
      return {
        ...state,
        chapters: [...copyArray],
      };
    case "PACKAGE_DATA":
      // console.log(action.payload, "====");
      return {
        ...state,
      };
    case "DELETE_CHAPTER":
      // console.log(action.payload);
      return {
        ...state,
        chapters: state?.chapters?.filter(
          (item) => item?._id !== action.payload
        ),
      };
    default:
      return state;
  }
};
export default chapterReducer;
